var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav menu-edit"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-chart-pie"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{attrs:{"to":"/perseroan/tableperseroan"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon far fa-building"}),_c('span',{staticClass:"menu-text"},[_vm._v("Perseroan")])])])]}}])}),_c('router-link',{attrs:{"to":"/nasabah/tablenasabah"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-friends"}),_c('span',{staticClass:"menu-text"},[_vm._v("Nasabah")])])])]}}])}),_c('router-link',{attrs:{"to":"/kerjasama/tablekerjasama"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-handshake"}),_c('span',{staticClass:"menu-text"},[_vm._v("Notaris Kerjasama")])])])]}}])}),_c('router-link',{attrs:{"to":"/pengurusanakta/tablepengurusanakta"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengurusan Perseroan")])])])]}}])}),_c('router-link',{attrs:{"to":"/pengurusanlain/tablepengurusanlain"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-file-invoice"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengurusan Nasabah")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(1),_c('router-link',{attrs:{"to":"/laporan/laporanperseroan"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Perseroan")])])])]}}])}),_c('router-link',{attrs:{"to":"/laporan/laporannasabah"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Nasabah")])])])]}}])}),_c('router-link',{attrs:{"to":"/laporan/laporanpenugasan"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Penugasan")])])])]}}])}),_c('router-link',{attrs:{"to":"/laporan/laporandokumen"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan Dokumen")])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":"/user/tableuser"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-network-wired"}),_c('span',{staticClass:"menu-text"},[_vm._v("User Management")])])])]}}])}),_vm._m(2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fab fa-ioxhost"}),_c('span',{staticClass:"menu-text"},[_vm._v("Laporan")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Laporan")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('img',{staticClass:"mt-10",staticStyle:{"width":"180px","height":"180px"},attrs:{"src":"media/logos/logo_footer.png"}})])
}]

export { render, staticRenderFns }