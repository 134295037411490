<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">User Notifications</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{tdNotif}}
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Notif
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Events
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="2"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Logs
          </a>
        </li> -->
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in dNotif">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-40 mr-5"
                v-bind:class="`symbol-light-blue`"
              >
                <span class="symbol-label">
                  <span
                    class="svg-icon svg-icon-lg"
                    v-bind:class="`svg-icon-blue`"
                  >
                    <!-- begin::Svg Icon -->
                    <inline-svg :src="svg" />
                    <!-- end::Svg Icon -->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a
                  @click="popUpNotif(item)"
                  class="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  {{ item.name }}
                </a>
                <span class="text-muted">
                  {{ item.deskripsi }}
                </span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
          <template>
             <v-dialog
                v-model="dialog"
                transition="dialog-bottom-transition"
                width="auto"
              >
                <!-- <template v-slot:activator="{ props }">
                  <v-btn
                    color="primary"
                    v-bind="props"
                  >From the bottom</v-btn>
                </template> -->
                <!-- <template v-slot:default="{ isActive }"> -->
                  <v-card>
                    <v-toolbar dark color="#fec5bb">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon style="color: #001219;">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color: #001219;">Detail Notif</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <div class="text-h4 pa-3">{{namePopUp}}</div>
                      <div class="text-h6 pa-2">{{noAktePopUp}}</div>
                      <div class="text-h6 pa-2">{{deskripsiPopUp}}</div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        variant="text"
                        @click="dialog = false"
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                <!-- </template> -->
              </v-dialog>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <!-- <b-tab>
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in list2">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i v-bind:class="item.icon"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.title }}
                  </div>
                  <div class="text-muted">
                    {{ item.desc }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new notifications.
        </div>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
// import Swal from "sweetalert2";
import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      list1: [
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "primary",
          svg: process.env.BASE_URL + "media/svg/icons/Home/Library.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "warning",
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Write.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "success",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Group-chat.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "danger",
          svg: process.env.BASE_URL + "media/svg/icons/General/Attachment2.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Shield-user.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Mail-notification.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg: process.env.BASE_URL + "media/svg/icons/Design/Bucket.svg"
        }
      ],
      list2: [
        {
          title: "New report has been received",
          desc: "23 hrs ago",
          icon: "flaticon2-line-chart text-success"
        },
        {
          title: "Finance report has been generated",
          desc: "25 hrs ago",
          icon: "flaticon2-paper-plane text-danger"
        },
        {
          title: "New order has been received",
          desc: "2 hrs ago",
          icon: "flaticon2-user flaticon2-line- text-success"
        },
        {
          title: "New customer is registered",
          desc: "3 hrs ago",
          icon: "flaticon2-pin text-primary"
        },
        {
          title: "Application has been approved",
          desc: "3 hrs ago",
          icon: "flaticon2-sms text-danger"
        },
        {
          title: "New file has been uploaded",
          desc: "5 hrs ago",
          icon: "flaticon2-pie-chart-3 text-warning"
        },
        {
          title: "New user feedback received",
          desc: "8 hrs ago",
          icon: "flaticon-pie-chart-1 text-info"
        },
        {
          title: "System reboot has been successfully completed",
          desc: "12 hrs ago",
          icon: "flaticon2-settings text-success"
        },
        {
          title: "New order has been placed",
          desc: "15 hrs ago",
          icon: "flaticon-safe-shield-protection text-primary"
        },
        {
          title: "Company meeting canceled",
          desc: "19 hrs ago",
          icon: "flaticon2-notification text-primary"
        },
        {
          title: "New report has been received",
          desc: "23 hrs ago",
          icon: "flaticon2-fax text-success"
        },
        {
          title: "Finance report has been generated",
          desc: "25 hrs ago",
          icon: "flaticon-download-1 text-danger"
        }
      ],
      dNotif: [],
      tdNotif: 0,
      svg: process.env.BASE_URL + "media/svg/icons/Communication/Mail-notification.svg",
      dialog: false,
      colorPopUp: '',
      namePopUp: '',
      deskripsiPopUp: '',
      noAktePopUp: ''
    };
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    getNotif(){
    var a = setInterval(()=>{
        this.displayNotif()
    }, 120000)
    a
      // setInterval(console.log('aa'), 3000)
    },
    displayNotif(){
      this.dNotif = []
        return new Promise(resolve => {
        var mydata = {
            // user_id_sender 	: storagelocal.getLocalStorage('UID'),
            Token		        : storagelocal.getLocalStorage('token'),
            Trigger	        : 'R',
            // options         : this.options,
            Route           : 'report_pengurusan_all_mobile',
            status          : 1,
            reminder        : 1,
            count_date      : 30,
            comparing       : '<=',
        }
        Services.PostData(ApiService, "t_pengurusan_akta", mydata, async response=>{
            var responseCace = response.data
            for(let i=0; i<responseCace.length; i++){
                responseCace[i].tgl_akte_conv = this.converterDate(responseCace[i].tgl_akte)
            }
            this.dNotif = responseCace
            this.tdNotif = response.total
            // this.getNotif()
            resolve(this.dNotif);
            }, err =>{
            err
            })
        });
    },
    converterDate(date){
      var convDate = new Date(date)
      var dd = String(convDate.getDate()).padStart(2, '0');
      var mm = String(convDate.getMonth() + 1).padStart(2, '0'); 
      var yyyy = convDate.getFullYear();
  
      return dd + '-' + mm + "-" + yyyy
    },
    popUpNotif(item){
      // console.log(item)
      this.dialog = true
      this.colorPopUp = item.color
      this.namePopUp = item.name
      this.deskripsiPopUp = item.deskripsi
      this.noAktePopUp = item.no_akte
    }
  },
  mounted(){
    this.getNotif()
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/stock-900x600/31.jpg";
    }
  }
};
</script>
