<template>
  <div
    class="quick-search quick-search-dropdown"
    id="kt_quick_search_dropdown"
  >
    <form method="get" class="quick-search-form">
      <div
        class="input-group spinner-input spinner-sm spinner-brand spinner-right"
        v-bind:class="{ spinner: loading }"
      >
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="flaticon2-search-1"></i>
          </span>
        </div>
        <input
          v-on:keyup.enter="onSearch"
          type="text"
          class="form-control quick-search-input"
          placeholder="Search Dokumen..."
          v-model="nama_dokumen"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i
              class="quick-search-close"
              v-on:click="reset"
              v-bind:style="{ display: hasResult() ? 'flex' : '' }"
            ></i>
          </span>
        </div>
      </div>
    </form>
    <!-- <KTSearchResult v-bind:nama_dokumen_bind="nama_dokumen_bind"></KTSearchResult> -->
  </div>
  <!-- <laporan-dokumen v-bind:nama_dokumen_bind="nama_dokumen_bind"></laporan-dokumen> -->
</template>

<script>
// import LaporanDokumen from "@/view/pages/laporan/laporan_dokumen/LaporanDokumen.vue";
// import KTSearchResult from "@/view/layout/extras/dropdown/SearchResult.vue";
// import ApiService from "@/core/services/api.service";
// import Services from "@/core/services/notasis-api/Services";
// import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "KTSearchDefault",
  // components: { KTSearchResult },
  data() {
    return {
      data: [],
      loading: false,
      nama_dokumen: '',
      nama_dokumen_bind: ''
    };
  },
  methods: {
    onSearch(event) {
      event
      // this.nama_dokumen_bind = this.nama_dokumen
      var oldPath = this.$route.fullPath;
      var newPath = `/laporan/laporandokumen?doc_name=${this.nama_dokumen}`
      if(oldPath !== newPath)
      this.$router.push({name: 'laporandokumen', query: {doc_name: this.nama_dokumen}})
      // event
      // if (event.target.value.length > 2) {
        // this.loading = true;
        // simulate getting search result
        // setTimeout(() => {
          // this.nama_dokumen = this.result;
          // this.loading = false;
        // }, 2000);
      // }
    },
    /**
     * Check if the data has result
     * @returns {boolean}
     */
    hasResult() {
      return this.data.length || false;
    },
    /**
     * Reset search data
     */
    reset() {
      this.data = [];
    }
  }
};
</script>
