<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span class="symbol symbol-35 symbol-light-primary mr-7 ml-0">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ username.charAt(0).toUpperCase() }}
        </span>
      </span>
      <span
        class="text-muted font-weight-bold font-size-h4 d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        v-if="username.length<8"
        class="text-dark-50 font-weight-bolder font-size-h4 d-none d-md-inline mr-3"
      >
        {{ username }}
      </span>
       <span
      v-if="username.length>=8"
        class="text-dark-50 font-weight-bolder font-size-h4 d-none d-md-inline mr-3"
      >
       {{ username.substring(0,8)+".." }}
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 400vh; position: relative; align-items: center; display: flex;"
      >
        <!--begin::Header-->
        <div class="col align-items-center d-flex flex-column mt-5">
          <div class="mb-5">
            <img
              :src="show_photo"
              alt=""
              style="width: 200px; height: 200px; border-radius: 5%"
            />
            <!-- <i class="symbol-badge bg-success"></i> -->
          </div>
          <div class="d-flex flex-column align-items-center">
            <router-link
              to="/setprofile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ username }}
            </router-link>
            <div class="text-muted mt-1">{{jabatan}}</div>
            <div class="navi mt-2">
              <!-- <a href="#" class="navi-item"> -->
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-primary">
                    {{ email }}
                  </span>
                </span>
              <!-- </a> -->
            </div>
            <div class="text-muted mt-1 mb-2">Sebagai {{level}} Notasis</div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      // username: "",
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ],
      username: '',
      photo_change: '',
      default_photo: "media/users/blank.png",
      show_photo: '',
      jabatan: '',
      email: '',
      level: ''
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    // this.username = storagelocal.getLocalStorage('username')
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    cekPhoto() {
      this.username = storagelocal.getLocalStorage('username')
      this.jabatan = storagelocal.getLocalStorage('jabatan')
      this.email = storagelocal.getLocalStorage('email')
      var getLevel = storagelocal.getLocalStorage('level')
      if(getLevel == 0){
        this.level = 'Admin'
      }else{
        this.level = 'User'
      }
      if(storagelocal.getLocalStorage('photo_conv').toString() == ''){
        this.show_photo = "media/users/blank.png"
      }else{
        this.show_photo = storagelocal.getLocalStorage('photo_conv')
      }
      return this.show_photo
    }
  },
  created(){
     this.cekPhoto()
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
  
  }
};
</script>
